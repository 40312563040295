import { emitter } from '@/registerServiceWorker.js';
import accountsApi from '@/modules/shared/api/accounts.js';
import { adminAccountsAxios } from '../admin/admin.api.js';

export async function login({ email, password }) {
  const { data } = await accountsApi().post('/public/login', {
    email,
    password,
  });

  return data;
}

export async function recovery({ email }) {
  await accountsApi().post('/public/recovery', {
    email,
  });
}

export async function fetchUser() {
  const { data: user } = await accountsApi().get('/private/users/me');

  // window.$crisp.push(['set', 'user:email', user.email]);
  emitter.emit('loggedIn', { userId: user._id });

  return { user };
}

export async function createApp({ slug, name, description, options, url, owner, address, tax }) {
  const { data } = await adminAccountsAxios.post('/skyloud/apps', {
    slug,
    name,
    description,
    options,
    url,
    owner,
    address,
    tax,
  });

  return data;
}

export async function resetPassword({ password }) {
  await accountsApi().post('/private/resetPassword', {
    password,
  });
}

export async function getAccessTokens() {
  const { data: accessTokens } = await accountsApi().get('/admin/accessTokens');

  return accessTokens;
}

export async function createAccessToken({ name }) {
  const { data: accessToken } = await accountsApi().post('/admin/accessTokens', {
    name,
  });

  return accessToken;
}

export async function deleteAccessToken({ accessTokenId }) {
  await accountsApi().delete(`/admin/accessTokens/${accessTokenId}`);
}
